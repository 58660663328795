<template>
	<reqsType :baseUrlpath="baseUrlpath" :reqType="reqType"></reqsType>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import reqsType from '@/components/reqsType.vue'
	export default {
		name: '工作机会',
		metaInfo: {
			title: '中东华人宝-工作机会',
			meta: [{
					name: 'keyWords',
					content: '工作机会-招聘-求职-猎头公司'
				},
				{
					name: 'description',
					content: '工程,财务/金融,保洁/家政,科技/电脑,销售/公关/市场,贸易,文娱传媒/设计,政府/公共事业,司机,教育/法律/翻译,建筑,仓储/物流,人事/行政管理,安保,厨师/烹饪,电商,劳力,娱乐休闲保健,体育健身,房产行业,汽车行业,高级管理,旅游/会展,餐饮/酒店,文秘/助理'
				}
			]
		},
		components: {
			reqsType
		},
		data() {
			return {
				baseUrlpath: '',
				reqType: '3'
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			var inchina = localStorage.getItem('inChina');
			if(inchina == '1'){
				this.baseUrlpath = this.baseUrlCN;
			}
		},
		methods: {}
	}
</script>

<style>
</style>