<template>
	<div>
		<div class="container">
			<div class="adwarp" @click="pushadvertise" v-if="zoneAds.length >= 3">
				<el-carousel :interval="4000" type="card" height="210px">
					<el-carousel-item v-for="(item,index) in zoneAds" :key="item.adPath + index" >
						<div class="advertise_bg">
							<div class="img">
								<img :src="baseUrlpath + item.adPath" />
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="adwarp" @click="pushadvertise" v-else-if="zoneAds.length > 0 && zoneAds.length < 3">
				<el-carousel :interval="4000" type="card" height="210px">
					<el-carousel-item v-for="(item,index) in zoneAds" :key="item.adPath + index" >
						<div class="advertise_bg">
							<div class="img">
								<img :src="baseUrlpath + item.adPath" />
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="adwarp" @click="pushadvertise" v-else>
				<el-carousel :interval="4000" type="card" height="160px">
					<el-carousel-item v-for="item in 6" :key="item" >
						<div class="advertise_bg">
							<div class="img"></div>
							<h3 class="medium">{{ '广告位'+item }}</h3>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="search-box">
				<div class="search-bd">
					<div class="search_t" v-if="reqType == '1'">
						<el-radio-group v-model="carryType">
							<el-radio :label="item.key" v-for="(item,index) in carryTypeList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '2'">
						<el-radio-group v-model="sfcarType">
							<el-radio :label="item.key" v-for="(item,index) in sfcarTypeList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '4'">
						<el-radio-group v-model="proTypeClassfy">
							<el-radio :label="item.key" v-for="(item,index) in xzProTypeList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '3'">
						<el-radio-group v-model="inviteType">
							<el-radio :label="item.key" v-for="(item,index) in inviteModelList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '3'">
						<el-radio-group v-model="inviteLabel">
							<el-radio :label="item.key" v-for="(item,index) in inviteLabels">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '11'">
						<el-radio-group v-model="fangTransType">
							<el-radio :label="item.key" v-if="item.key != '0'" v-for="(item,index) in fangTransTypeList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '10'">
						<el-radio-group v-model="travelType">
							<el-radio :label="item.key" v-for="(item,index) in travelTypelList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '9'">
						<el-radio-group v-model="carTransType">
							<el-radio :label="item.key" v-if="item.key != '0' &&  item.key != '3'" v-for="(item,index) in carTransList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '12'">
						<el-radio-group v-model="delicacyType">
							<el-radio :label="item.key" v-for="(item,index) in delicacyTypeList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '14'">
						<el-radio-group v-model="roastType">
							<el-radio :label="item.key" v-for="(item,index) in roastTypeList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_t" v-if="reqType == '15'">
						<el-radio-group v-model="localContactType">
							<el-radio :label="item.key" v-for="(item,index) in localContactTypeList">{{item.value}}</el-radio>
						</el-radio-group>
					</div>
					<div class="search_c">
						<div class="search_item">
							<div class="sear_it_head">地区：</div>
							<el-cascader
							    v-model="searchAreaId"
								:props="cascaderprops"
							    :options="citysList">
							</el-cascader>
						</div>
						<div class="search_item">
							<el-checkbox v-model="issm">已实名</el-checkbox>
							<el-checkbox v-model="isdb">已担保</el-checkbox>
						</div>
					</div>
				</div>
				<div class="search_btn_warp" @click="dealSearch">
					<div class="search_btn">
						<div class="icon_search"></div>
						<div>搜索</div>
					</div>
					<div class="rest_btn" @click="goPublish">
						我要发布
					</div>
				</div>
				<!-- <div class="store_guide" v-if="reqType == '1' || reqType == '10' || reqType == '3' || reqType == '11' || reqType == '9' || reqType == '12' || reqType == '2'">
					<div v-if="reqType == '1'" class="tfc-item" @click="changeClassify()">物流公司专区</div>
					<div v-else-if="reqType == '10'" class="tfc-item" @click="changeClassify()">旅行社专区</div>
					<div v-else-if="reqType == '3'" class="tfc-item" @click="changeClassify()">猎头公司专区</div>
					<div v-else-if="reqType == '2'" class="tfc-item" @click="changeClassify()">运输公司专区</div>
					<div v-else-if="reqType == '11'" class="tfc-item" @click="changeClassify()">房产中介/房产经纪人专区</div>
					<div v-else-if="reqType == '9'" class="tfc-item" @click="changeClassify()">修理厂/车行专区</div>
					<div v-else-if="reqType == '12'" class="tfc-item" @click="changeClassify()">餐厅专区</div>
					<div class="more"></div>
				</div> -->
			</div>
			<div v-loading="searchLoading" style="margin-top: 40px;">
				<div v-if="tableData.length > 0" class="search_body">
					<div class="req-warp" >
						<div v-for="(item,index) in tableData" :key="item.sdr_reqId" class="req-item itm_ml" >
							<reqInfo :baseUrlpath="baseUrlpath" :item="item"></reqInfo>
						</div>
					</div>
					<div class="pagination_warp">
						<el-pagination
							@current-change="handleCurrentChange"
							:current-page.sync="currentPage1"
							:page-size="pageSize"
							layout="total, prev, pager, next"
							:total="total">
						</el-pagination>
					</div>
				</div>
				<div v-else class="empty-msg">
					<el-empty description="暂无内容"></el-empty>
				</div>
			</div>
			
		</div>

	</div>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import {
		mapGetters,
		mapActions
	} from 'vuex'
	import reqInfo from '@/components/reqInfo.vue'
	export default {
		components: {
			reqInfo
		},
		props: {
			reqType: {
				type: String,
				required: true
			},
			baseUrlpath: {
				type: String,
				required: true
			},
			seachWord:{
				type: String,
				required: false
			}
		},
		data() {
			return {
				searchLoading:false,
				currentPage1: 1,
				tableData: [],
				zoneAds:[],
				total: 0,
				pageSize: 8,
				xzProTypeList:[],
				travelTypelList:[],
				inviteModelList:[],
				inviteLabels:[],
				fangTransTypeList:[],
				carTransList:[],
				delicacyTypeList:[],
				roastTypeList:[],
				localContactTypeList:[],
				carryTypeList:[],
				sfcarTypeList:[],
				cascaderprops:{
					value:'id',
					label:'name',
					children:'child'
				},
				citysList:[0,0],
				searchAreaId:'',
				issm:false,
				isdb:false,
				proTypeClassfy:'all',
				travelType:'all',
				roastType:'all',
				delicacyType:'all',
				inviteType:'all',
				inviteLabel:'all',
				fangTransType:'all',
				carTransType:'all',
				localContactType:'all',
				sfcarType:'all',//0:车找人 1:人找车
				carryType:'all'
			}
		},
		created() {
			this.citysList = JSON.parse(JSON.stringify(this.$util.citysList));
			this.citysList.unshift({id:'all',name:'不限',child:[{id:'all',name:'不限'}]})
			//闲着市场
			this.xzProTypeList = JSON.parse(JSON.stringify(this.$util.xzProTypeList));
			this.xzProTypeList.unshift({'key':'all','value':'不限'})
			//招聘
			this.inviteModelList = JSON.parse(JSON.stringify(this.$util.inviteModelList));
			this.inviteModelList.unshift({'key':'all','value':'不限'})
			
			this.inviteLabels = JSON.parse(JSON.stringify(this.$util.inviteLabels));
			this.inviteLabels.unshift({'key':'all','value':'不限'})
			//房
			this.fangTransTypeList = JSON.parse(JSON.stringify(this.$util.fangTransModel));
			this.fangTransTypeList.unshift({'key':'all','value':'全部'})
			//机车
			this.carTransList = JSON.parse(JSON.stringify(this.$util.carTransModel));
			this.carTransList.unshift({'key':'all','value':'全部'})
			//美食咨询
			this.delicacyTypeList = JSON.parse(JSON.stringify(this.$util.delicacyLebels)); 
			this.delicacyTypeList.unshift({'key':'all','value':'全部'})
			//吐槽
			this.roastTypeList = JSON.parse(JSON.stringify(this.$util.businessOppoLebels)); 
			this.roastTypeList.unshift({'key':'all','value':'全部'})
			//本地黄页 -- 通讯录
			this.localContactTypeList = JSON.parse(JSON.stringify(this.$util.localContactsLabels));
			this.localContactTypeList.unshift({'key':'all','value':'全部'})
			//旅游达人
			this.travelTypelList = JSON.parse(JSON.stringify(this.$util.travelLebels));
			this.travelTypelList.unshift({'key':'all','value':'全部'})
			//带货模式
			this.carryTypeList = JSON.parse(JSON.stringify(this.$util.carryModelList));
			this.carryTypeList.unshift({'key':'all','value':'全部'})
			//顺风车模式
			this.sfcarTypeList = JSON.parse(JSON.stringify(this.$util.carModelList));
			this.sfcarTypeList.unshift({'key':'all','value':'全部'})
			this.queryZoneAd();
			this.query();
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		methods: {
			dealSearch(){
				this.currentPage1 = 1;
				this.pageSize = 8;
				this.query();
			},
			changeClassify(){
				this.$router.push({
				  name: '/main/storeRecommendList',
				  query: {reqType:this.reqType}
				});
			},
			query() {
				this.searchLoading = true
				var vm = this;
				var params = {};
				params.currentPage = vm.currentPage1;
				params.pageSize = vm.pageSize;
				if(this.searchAreaId == ''){
					params.areaId = "['all','all']";
				}else{
					params.areaId = JSON.stringify(this.searchAreaId);
				}
				params.sfcarType = this.sfcarType;
				params.carryType = this.carryType;
				params.proTypeClassfy = this.proTypeClassfy;
				params.travelType = this.travelType;
				params.localContactType = this.localContactType;
				params.roastType = this.roastType;
				params.inviteType = this.inviteType;
				params.inviteLabel = this.inviteLabel;
				params.reqType = this.reqType;
				params.carTransType = this.carTransType;
				params.fangTransType = this.fangTransType;
				params.delicacyType = this.delicacyType;
				params.smTypeId = this.issm ? '1' : '0';
				params.dbTypeId = this.isdb ? '1' : '0';
				if(this.seachWord != null && this.seachWord != undefined && this.seachWord.length > 0){
					params.seachWord = this.seachWord
				}
				var requestUrl = '/apis/getReqs'
				var inchina = localStorage.getItem('inChina');
				if(inchina == '1'){
					requestUrl = '/cnapis/getReqs'
				}
				queryUrl(requestUrl, params).then(res => {
					vm.tableData = res.reqList;
					vm.total = res.totalcount;
					vm.searchLoading = false
				});
			},
			async queryZoneAd() {
				var vm = this;
				var params = {};
				params.adLunchType = "1";
				params.reqType = this.reqType;
				var requestUrl = '/apis/queryAds'
				var inchina = localStorage.getItem('inChina');
				if(inchina == '1'){
					requestUrl = '/cnapis/queryAds'
				}
				queryUrl(requestUrl, params).then(res => {
					vm.zoneAds = res.reList;
				});
			},
			handleCurrentChange(e){
				// console.log(e)
				this.currentPage1 = e;
				this.tableData = [];
				this.total = 0;
				this.query()
			},
			goPublish(){
				var url = '';
				if(this.reqType == '1'){
					url = 'pubcommerce'
				}else if(this.reqType == '2'){
					url = 'pubsfc'
				}else if(this.reqType == '3'){
					url = 'pubrecruit'
				}else if(this.reqType == '4'){
					url = 'pubsale'
				}else if(this.reqType == '5'){
					url = 'pubactivity'
				}else if(this.reqType == '7'){
					url = 'pubDiscounts'
				}else if(this.reqType == '9'){
					url = 'publocomotive'
				}else if(this.reqType == '10'){
					url = 'pubtravel'
				}else if(this.reqType == '11'){
					url = 'pubhouse'
				}else if(this.reqType == '12'){
					url = 'pubDelicacy'
				}else if(this.reqType == '13'){
					url = 'pubCnyGoodExpt'
				}else if(this.reqType == '14'){
					url = 'pubRoast'
				}else if(this.reqType == '15'){
					url = 'pubTel'
				}else if(this.reqType == '16'){
					url = 'pubRate'
				}
				this.$router.push({
				  name: url,
				  query: {reqType:this.reqType}
				});
			},
			pushadvertise(){
				if (!this.userInfo.fayanboId) {
					this.$util.warnMsg("请登录系统！")
					this.$store.dispatch('setLoginDialogvisible', true)
				} else {
					this.$router.push({
						name: '/my/advertisements'
					});
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.adwarp {
		margin-bottom: 10px;
	}
	.advertise_bg {
		height: 100%;
		position: relative;
		.img {
			height: 100%;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.medium {
			position: absolute;
			top: 50%;
			left: 50%;
		}
	}
	.store_guide {
		position: absolute;
		bottom: 10px;
		right: 20px;
		font-size: 24px;
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: #0000FF;
		text-decoration:underline;
		.more {
			background: url(../assets/icon/more.png) center no-repeat;
			background-size: 100%;
			width: 40px;
			height: 40px;
		}
	}
	
	.store_guide:hover {

	}
	.el-radio-group {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		.el-radio {
			margin-bottom: 16px;
		}
	}
	.search_body {
		min-height: 760px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		.pagination_warp {
			padding: 0 20px 20px 0;
			margin-top: 60px;
		}
	}
	.search-box {
		padding: 24px;
		box-shadow: 0 4px 16px rgba(69,88,115,.2);
		margin-bottom: 16px;
		background: #fff;
		border-radius: 12px;
		position: relative;
		.search-bd {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			min-height: 76px;
			.search_t {
				margin-bottom: 20px;
			}
			.search_c {
				margin-bottom: 20px;
				display: flex;
				.search_item {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					.sear_it_head {
						font-size: 14px;
					}
				}
				.search_item:not(:last-child) {
					margin-right: 20px;
				}
			}

		}
		.search_btn_warp {
			display: flex;
			position: absolute;
			left: 50%;
			bottom: -46px;
			position: absolute;
			transform: translate(-50%,-50%);
			.search_btn {
				background-image: linear-gradient(-90deg,#c88854,#e09a62);
				border-radius: 28px;
				box-shadow: 0 4px 16px 0 rgba(255,102,0,.32);
				width: 132px;
				height: 46px;
				border: none;
				display: block;
				outline: none;
				font-size: 18px;
				color: #fff;
				z-index: 2;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				margin-right: 8px;
				.icon_search {
					background: url(../assets/icon/search_empty.png) no-repeat center;
					background-size: 100%;
					width: 26px;
					height: 26px;
					margin-right: 6px;
				}
			}
			.rest_btn {
				width: 132px;
				height: 46px;
				color: #C88854;
				border: 1px solid #C88854;
				line-height: 46px;
				cursor: pointer;
				border-radius: 28px;
				font-size: 18px;
				box-sizing: border-box;
				margin-left: 8px;
				background-color: #FFFFFF;
			}
			.rest_btn:hover {
				border: none;
				background-color: #C88854;
				color: #FFF7F0;
				outline: none;
			}
		}
	}
	.req-warp {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		.req-item {
			width: calc((100% - 36px) / 4);
			border-radius: 8px;
			box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
			overflow: auto;
			margin-bottom: 14px;
		}
		.itm_ml:not(:nth-child(4n)) {
			margin-right: 12px;
		}
	}

	.container {
		width: 90%;
		margin: 0 auto;
		background-color: #FFFFFF;
		.empty-msg {
			flex: 1;
			height: calc(100vh - 70px);
			display: flex;
			justify-content: center;
		}
	}

	
</style>
