<template>
	<reqsType :baseUrlpath="baseUrlpath" :reqType="reqType"></reqsType>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import reqsType from '@/components/reqsType.vue'
	export default {
		name: '活动组织',
		metaInfo: {
			title: '中东华人宝-活动组织',
			meta: [{
					name: 'keyWords',
					content: '活动组织'
				},
				{
					name: 'description',
					content: '活动组织'
				}
			]
		},
		components: {
			reqsType
		},
		data() {
			return {
				baseUrlpath: '',
				reqType: '5'
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			var inchina = localStorage.getItem('inChina');
			if(inchina == '1'){
				this.baseUrlpath = this.baseUrlCN;
			}
		},
		methods: {}
	}
</script>

<style>
</style>