<template>
  <reqsType :baseUrlpath="baseUrlpath" :reqType="reqType" :seachWord="seachWord"></reqsType>
</template>

<script>
  import {queryUrl,doPost} from '@/api/index';
  import { Toast } from 'mint-ui';
  import reqsType from '@/components/reqsType.vue'
  export default {
    components:{
      reqsType
    },
    data() {
      return {
       baseUrlpath:'',
       reqType:'all',
	   seachWord:''
      }
    },
    created(){
      this.baseUrlpath = this.baseUrl;
	  var inchina = localStorage.getItem('inChina');
	  if(inchina == '1'){
	  	this.baseUrlpath = this.baseUrlCN;
	  }
	  this.seachWord = this.$route.query.searchConts;
    },
    methods: {
    }
  }
</script>

<style>
</style>
