<template>
	<div class="container">
		<div class="flex-between headwarp">
			<div class="head-item" @click="goChooseClassfiy()">1.选择类别</div>
			<div class="head-item active">2.填写信息</div>
			<div class="head-item">3.完成发布</div>
		</div>
		<div class="cont-body">
			<div class="content">
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">标签<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b">
						<el-radio-group v-model="tagIndex" class="radio_warp">
							<div v-for="(item,index) in delicacyTags" :key="item.key" class="radio_itm_warp">
								<el-radio :label="item.key">{{item.value}}</el-radio>
							</div>
						</el-radio-group>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">城市<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b">
						<el-cascader
						    v-model="areaId"
							:props="cascaderprops"
						    :options="citysList"
						    @change="handleCityChange">
						</el-cascader>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">价格<label v-if="CurrencySymbol.length > 0">({{CurrencySymbol}})</label></div>
						<div class="pub_coin_unit_desc">面向地区所在国家币种</div>
					</div>
					<div class="ctx-item-b" >
						<div class="hxs-warp">
							<el-input v-model="price" maxlength="8" placeholder="请输入价格" />
						</div>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">餐厅名称<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b" >
						<el-input placeholder="请输入餐厅名称" v-model="restaurantName" maxlength="30" />
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">餐厅地址<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b" >
						<el-input placeholder="请输入餐厅地址" v-model="activityPlace" maxlength="80" />
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">是否置顶（30天）<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b" style="text-align: right;">
						<el-switch
							v-model="tickTopOrNot"
							@change="tickTopChange"
							active-color="#13ce66"
							inactive-color="#ff4949">
						</el-switch>
					</div>
				</div>
				<div class="ctx-item inlinediv">
					<pickContact ref="pickContact" :queryUid="userInfo.fayanboId" :baseUrlpath="baseUrlpath" @changeConcactShow="changeConcactShow" :isShowConcact="isShowConcact" :isDetail="isDetail"></pickContact>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">详细说明<label class="bishu">*</label></div>
						<div class="ctx-item-headdesc">(稍加修饰点缀才有吸引力哦~)</div>
					</div>
					<div class="ctx-item-b">
						<el-input type="textarea" v-model="reqNail" :autosize="{ minRows: 4}" maxlength="200" placeholder="请输入内容" show-word-limit></el-input>
					</div>
				</div>
				<div class="ctx-item marb200">
					<div class="ctx-item-t">
						<div class="ctx-item-head">上传照片/视频<label class="bishu">*</label></div>
						<div class="ctx-item-headdesc"><label>({{finalFileList.length}}/{{limitFilesNum}})</label></div>
					</div>
					<div class="ctx-item-b">
						<uploadFile :baseUrlpath="baseUrlpath" :fileList="fileList" @addOrMulitiFileList="addOrMulitiFileList"></uploadFile>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-b">
						<div class="pub_jd_cost">
							<div class="jd_total">
								<label class="unit">共需花费</label>
								<label class="num">{{pubTotalCost}}</label>
								<label class="unit">金豆</label>
							</div>
							<div class="cost_desc">
								<label>发布</label>
								<label class="num">{{pubNeedCost}}</label>
								<label>金豆</label>
								<template v-if="tickTopOrNot">
									<label>+</label>
									<label>置顶</label>
									<label class="num">{{tickTopNeedCost}}</label>
									<label>金豆</label>
								</template>
							</div>
						</div>
					</div>
				</div>
				<div class="btn-foot">
					<div class="btn" @click="publish()">确认发布</div>
				</div>
			</div>	
		</div>
	</div>
</template>

<script>
	import {mapGetters,mapActions} from 'vuex'
	import uploadFile from '@/components/upload/uploadFile.vue'
	import pickContact from '@/components/pickContact.vue'
	import {queryUrl,doPost,commitFileUrl,queryUrlWithUInfo,postWithUInfo} from '@/api/index';
	import { Toast } from 'mint-ui';
	export default {
		components:{
		  uploadFile,pickContact
		},
		data(){
			return {
				reqType:'',
				multiArray:[],
				array:[],
				citysList:[],
				cascaderprops:{
					value:'id',
					label:'name',
					children:'child'
				},
				tagIndex: '6',
				activityPlace:'',
				CurrencySymbol:'',
				price:'',
				areaId:'',
				reqNail:'',
				fileList:[],
				finalFileList:[],
				limitFilesNum:9,
				baseUrlpath:'',
				delicacyTags:[],
				restaurantName:'',
				reqNo:'',
				isShowConcact:true,
				tickTopOrNot:false,
				tickTopNeedCost:0,
				pubNeedCost:0,
				pubTotalCost:0,
				isDetail:false,
				sdr_userId:''
			}
		},
		created() {
			// console.log(this.$route.query)
			this.reqType = this.$route.query.reqType;
			this.citysList = this.$util.citysList;
			this.delicacyTags = this.$util.delicacyLebels;
			this.queryTickJdCost()
			this.queryPubJdCost()
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		watch:{
			tickTopNeedCost:{
				handler(val, oldval) {
					if(this.tickTopOrNot){
						this.pubTotalCost = parseInt(val) + parseInt(this.pubNeedCost) 
					}else{
						this.pubTotalCost = parseInt(this.pubNeedCost)
					}
				}
			},
			pubNeedCost:{
				handler(val, oldval) {
					if(this.tickTopOrNot){
						this.pubTotalCost = parseInt(val) + parseInt(this.tickTopNeedCost) 
					}else{
						this.pubTotalCost = parseInt(val)
					}
				}
			},
			tickTopOrNot:{
				handler(val, oldval) {
					console.log(val)
					if(val){
						this.pubTotalCost = parseInt(this.pubNeedCost) + parseInt(this.tickTopNeedCost) 
					}else{
						this.pubTotalCost = parseInt(this.pubNeedCost)
					}
				}
			}
		},
		methods:{
			tickTopChange(e){
				var needCost = this.tickTopNeedCost;
				var _this = this;
				console.log(e)
				if(e){
					this.$confirm('置顶需花费'+needCost+'金豆'+', 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						
					}).catch(() => {
						_this.tickTopOrNot = false       
					});
				}
			},
			async queryTickJdCost(){
				const initres = await queryUrlWithUInfo('/apis/queryJdNeedCost',{queryType:'1'});
				if(initres.ec == '0'){
					this.tickTopNeedCost = parseInt( initres.jdCost)
				}
			},
			async queryPubJdCost(){
				const initres = await queryUrlWithUInfo('/apis/pubJdNeedCost',{queryType:'1'});
				if(initres.ec == '0'){
					this.pubNeedCost = parseInt(initres.jdCost)
				}
			},
			changeConcactShow(val){
				this.isShowConcact = val
			},
			goBack() {
				this.$util.goBack();
			},
			async publish(){
				var vm = this;
				
				if(this.areaId.length == 0){
					this.$util.warnMsg('请选择城市');
					return false;
				}
				/* if(this.price.length == 0){
					this.$util.warnMsg('请输入价格');
					return false;
				} */
				if(this.reqNail.length == 0){
					this.$util.warnMsg('请输入详细说明');
					return false;
				}
				if(this.activityPlace.length == 0){
					this.$util.warnMsg('请输入餐厅地址');
					return false;
				}
				if(this.restaurantName.length == 0){
					this.$util.warnMsg('请输入餐厅名称');
					return false;
				}
				vm.$util.showMyLoading('加载中');
				var params = {};
				params.reqType = "12";
				params.reqNail = this.$util.textFormat(this.reqNail);
				params.dqModel = JSON.stringify(this.areaId);
				params.fileList = JSON.stringify(this.finalFileList);
				params.price = this.price;
				params.restaurantName = this.restaurantName;
				params.delicacyTag = this.tagIndex;
				params.activityPlace  = this.activityPlace;
				params.isShowConcact = this.isShowConcact ? '1' : '0';
				params.tickTopOrNot = this.tickTopOrNot ? '1' : '0';
				const initres = await postWithUInfo('/apis/publishReqPost',params);
				if(initres.ec == '0'){
					vm.$util.hideMyLoading();
					this.$util.msg("发布成功",{},()=>{
						vm.$util.goBack();
					});
				}else{
					vm.$util.hideMyLoading();
					this.$util.msg("发布失败，请联系客服",{},()=>{
						
					});
				}
			},
			goChooseClassfiy(){
				this.$router.push({
				  name: 'publishIndex',
				  query: {}
				});
			},
			handleCityChange(e){
				// console.log(e)
				this.queryCurrencySymbol();
			},
			async queryCurrencySymbol(){
				const initres = await queryUrlWithUInfo('/apis/queryCurrencySymbol',{'dqModel':JSON.stringify(this.areaId)});
				if(initres.ec == '0'){
					this.CurrencySymbol = initres.currencySymbol;
				}
			},
			addOrMulitiFileList(params){
				// console.log(params)
				if(params.type == '1'){
					this.finalFileList.push({fileId:params.file.fileId,path:params.file.path,url:params.file.path,thumbPath:params.file.thumbPath})
					// console.log(this.fileList)
				}else if(params.type == '2'){
					this.finalFileList = this.finalFileList.filter((item,index) => {
						return item.fileId != params.file.fileId
					});
					// console.log(this.fileList)
				}
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.radio_warp {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		.radio_itm_warp {
			margin: 0px 10px 10px 0px;
		}
	}
	.container {
		width: 90%;
		margin: 0 auto;
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		height: 100%;
		padding: 10px 20px;
		.headwarp {
			.head-item {
				width: 30%;
				text-align: left;
				line-height: 50px;
				border-bottom: 2px solid #F1F1F1;
			}
			.active {
				border-bottom: 2px solid #C88854;
			}
		}
		.cont-body {
			margin-top: 20px;
			//overflow: scroll;
			min-height: calc(100% - 100px);
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.ctx-item {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 10px 0;
					width: 600px;
					.bishu {
						color: #FF0000;
						margin-left: 4px;
					}
					.ctx-item-t {
						margin-right: 10px;
						.ctx-item-head {
							line-height: 40px;
							width: 160px;
							text-align: left;
							font-size: 14px;
							font-weight: 700;
						}
						.ctx-item-headdesc {
							font-size: 12px;
							text-align: left;
						}
					}
					.ctx-item-b {
						flex: 1;
						text-align:left;
						.hxs-warp {
							width: 100px !important;
							margin-right: 10px;
						}
						
					}
				}
			}
			.btn-foot {
				margin-top: 10px;
				.btn {
					width: 100px;
					margin: 0 auto;
					background-color: #c88854;
					color: #fff;
					padding: 10px 20px;
					border-radius: 30px;
					cursor: pointer;
				}
			}
		}
	}
</style>
