<template>
	<div class="container">
		<div class="ct-head">
			<div class="ch_item" :class="[{'active':(activeIndex == '0')}]">
				<div class="num">989</div>
				<div class="desc">金豆余额</div>
			</div>
			<div class="ch_item" :class="[{'active':(activeIndex == '1')}]">
				<div class="num">88</div>
				<div class="desc">钱包余额</div>
			</div>
		</div>
		<div class="ct-body">
			<div>
				
			</div>
			<div class="timeline_warp">
				<el-timeline>
				    <el-timeline-item timestamp="2018/4/12" placement="top">
				      <el-card>
				        <h4>更新 Github 模板</h4>
				        <p>王小虎 提交于 2018/4/12 20:46</p>
				      </el-card>
				    </el-timeline-item>
				    <el-timeline-item timestamp="2018/4/3" placement="top">
				      <el-card>
				        <h4>更新 Github 模板</h4>
				        <p>王小虎 提交于 2018/4/3 20:46</p>
				      </el-card>
				    </el-timeline-item>
				    <el-timeline-item timestamp="2018/4/2" placement="top">
				      <el-card>
				        <h4>更新 Github 模板</h4>
				        <p>王小虎 提交于 2018/4/2 20:46</p>
				      </el-card>
				    </el-timeline-item>
				  </el-timeline>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activities: [{
					content: '活动按期开始',
					timestamp: '2018-04-15'
				}, {
					content: '通过审核',
					timestamp: '2018-04-13'
				}, {
					content: '创建成功',
					timestamp: '2018-04-11'
				}],
				activeIndex:'0'
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.container {
		width: 90%;
		margin: 0 auto;
		.ct-head {
			display: flex;
			justify-content: space-around;
			.ch_item {
				width: 300px;
				height: 200px;
				border: 1px solid #efeeee;
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				cursor: pointer;
				box-sizing: border-box;
				.num {
					font-size: 50px;
					color: #c88854;
					font-weight: bold;
					margin-bottom: 30px;
				}
				.desc {
					font-size: 14px;
				}
			}
			.ch_item:hover {
				background-color: #ffede3;
				border: 0px;
			}
			.active {
				background-color: #ffede3;
				border: 0px;
			}
		}
		.ct-body {
			.timeline_warp {
				width: 70%;
				margin: 0 auto;
				padding-top: 45px;
				text-align: left;
			}
		}
	}
</style>
