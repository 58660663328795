<template>
	<div class="container">
		<el-page-header @back="goBack" content="常见问题">
		</el-page-header>
		<div class="conts">
			<el-collapse v-model="activeNames" @change="handleChange">
				<el-collapse-item v-for="(item,index) in relist" :title="item.sdh_tit" :name="item.sdh_id" :key="item.sdh_id">
				  <div>{{item.sdh_conts}}</div>
				</el-collapse-item>
			</el-collapse>
		</div>
		
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,
		queryUrlWithUInfo,
		postWithUInfo
	} from '@/api/index';
	export default {
		data() {
			return {
				relist:[]
			}
		},
		created() {
			this.getHelpList()
		},
		methods:{
			goBack() {
				this.$util.goBack();
				//this.$router.go(-1)
			},
			async getHelpList(){
				var params = {};
				const initres = await queryUrlWithUInfo("/apis/getHelpList", params);
				if (initres.ec == '0') {
					this.relist = initres.reList
				} 
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.container {
		width: 90%;
		margin: 0 auto;
		height: 100%;
	}
	.conts {
		min-height: 900px;
		margin-top: 20px;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		padding: 20px 10px 50px 10px;
	}
</style>
