<template>
	<reqsType :baseUrlpath="baseUrlpath" :reqType="reqType"></reqsType>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import reqsType from '@/components/reqsType.vue'
	export default {
		name: '房产仓库',
		metaInfo: {
			title: '中东华人宝-房产仓库',
			meta: [{
					name: 'keyWords',
					content: '房产仓库'
				},
				{
					name: 'description',
					content: '我要买房,房屋出售,仓库出售,办公室出售,房屋招租,仓库招租,办公室招租,合租信息,房产中介,房产经纪人'
				}
			]
		},
		components: {
			reqsType
		},
		data() {
			return {
				baseUrlpath: '',
				reqType: '11'
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			var inchina = localStorage.getItem('inChina');
			if(inchina == '1'){
				this.baseUrlpath = this.baseUrlCN;
			}
		},
		methods: {}
	}
</script>

<style>
</style>