<template>
	<reqsType :baseUrlpath="baseUrlpath" :reqType="reqType"></reqsType>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import reqsType from '@/components/reqsType.vue'
	export default {
		name: '本地通讯',
		metaInfo: {
			title: '中东华人宝-本地通讯',
			meta: [{
					name: 'keyWords',
					content: '本地通讯'
				},
				{
					name: 'description',
					content: '酒店/民宿,本地旅行社,汽修/汽配,中餐厅,KTV&按摩、娱乐,房产中介/房产经纪人,车行/租车行,手机、家电、电脑维修,物流、仓储、快递,翻译、教育,理发店,超市、商店,鲜花、蛋糕店,家政、搬家,医院、诊所,装修装潢,银行服务、金融,婚庆、摄影、视频服务,律师、移民、保险,会计事务所,贸易,代购/跑腿,商务咨询、商务中心,广告、传媒,美容美甲,商会、公益机构,全球领保热线,本地使馆,急救电话,火警电话,'
				}
			]
		},
		components: {
			reqsType
		},
		data() {
			return {
				baseUrlpath: '',
				reqType: '15'
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			var inchina = localStorage.getItem('inChina');
			if(inchina == '1'){
				this.baseUrlpath = this.baseUrlCN;
			}
		},
		methods: {}
	}
</script>

<style>
</style>